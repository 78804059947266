import React, { useState } from 'react';
import { Box, Button, Heading, ResponsiveContext } from 'grommet';
import StoryCard from './StoryCard';

const pad = {
  small: 'large',
  hero: 'medium',
};

export default function({ Link, background, headline, cards, buttonText }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const expandedCards = expanded ? cards : cards.slice(0, 3);
        const small = size === 'small';
        const hero = size === 'hero';

        return (
          <Box background={background || 'white'} pad={small || hero ? pad[size] : 'xlarge'}>
            <Heading
              margin={{ top: 'none', bottom: 'xlarge' }}
              level="2"
              alignSelf="center"
              textAlign="center"
            >
              {headline}
            </Heading>
            <Box direction="row-responsive" wrap>
              {expandedCards &&
                expandedCards.map((card, index) => (
                  <StoryCard
                    Link={Link}
                    basis={small ? 'auto' : 'calc(33.33333% - 16px)'}
                    margin={{
                      bottom: small ? 'large' : 'medium',
                      right: small || (index + 1) % 3 === 0 ? 'none' : 'medium',
                    }}
                    {...card}
                  />
                ))}
            </Box>
            {cards.length > 3 && buttonText && !expanded && (
              <Button
                alignSelf="center"
                label={buttonText}
                minWidth="200px"
                onClick={() => setExpanded(true)}
              />
            )}
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
