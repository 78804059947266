import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { Box, Heading, Text } from 'grommet';

const Container = styled(Box).attrs(({ background }) => ({
  background,
  pad: { vertical: 'large', horizontal: 'xlarge' },
}))``;

const Title = styled(Heading).attrs({
  level: 1,
  margin: {
    vertical: 'small',
  },
})``;

const Meta = styled(Text)`
  font-style: italic;
`;

export default function StoryHeader({ categoryName, headline, authorName, date, background }) {
  return (
    <Container background={background}>
      {categoryName && (
        <Heading level="4" size="14px" margin="0" uppercase>
          {categoryName}
        </Heading>
      )}
      <Title>{headline}</Title>
      <Box>
        <Meta>
          {authorName && <>By {authorName} on </>}
          {date && format(new Date(date), 'MMMM d, yyyy')}
        </Meta>
      </Box>
    </Container>
  );
}
