import React from 'react';
import CtaBanner from '../components/CtaBanner';
import Hero from '../components/HeroInteriorAlt';
import HighlightList from '../components/HighlightList';
import LogoGridProduct from '../components/LogoGridProduct';
import PricingInsightsOfficer from '../components/PricingInsightsOfficer';

export default function({ doc, Link }) {
  if (!doc) return null;

  const { hero, highlights, pricing, logos, cta } = doc;

  return (
    <>
      <Hero Link={Link} {...hero} />
      <HighlightList Link={Link} {...highlights} />
      <LogoGridProduct Link={Link} {...logos} />
      <PricingInsightsOfficer Link={Link} {...pricing} />
      <CtaBanner Link={Link} {...cta} />
    </>
  );
}
