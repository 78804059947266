import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Box, Heading, Paragraph, ResponsiveContext } from 'grommet';
import GatsbyButton from '../GatsbyButton';
import YouTubeEmbed from '../VideoEmbed/YouTubeEmbed';

const HighlightImg = styled(Img)`
  ${({ primary }) => !primary && 'box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.1);'}
`;

const textPad = {
  small: 'large',
  hero: 'medium',
};

const imgPad = {
  small: 'large',
  medium: 'large',
  large: 'large',
  hero: 'medium',
};

export default function({
  Link,
  body,
  buttonText,
  buttonUrl,
  headline,
  image,
  primary,
  reverse,
  video,
}) {
  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const direction = !reverse ? 'row' : 'row-reverse';
        const small = size === 'small';
        const hero = size === 'hero';

        return (
          <Box direction={small ? 'column' : direction} align={small ? 'start' : 'center'}>
            <Box
              basis={small ? 'auto' : '1/2'}
              fill="horizontal"
              justify="center"
              pad={{
                left: !reverse || small ? imgPad[size] : 'medium',
                right: !reverse && !small ? 'medium' : imgPad[size],
                // left: !reverse ? imgPad[size] : 'medium',
              }}
            >
              {video ? (
                <YouTubeEmbed url={video} />
              ) : (
                image && (
                  <HighlightImg fluid={image.localFile.childImageSharp.fluid} primary={primary} />
                )
              )}
            </Box>
            <Box
              basis={small ? 'auto' : '1/2'}
              pad={small || hero ? textPad[size] : 'xlarge'}
              align="start"
              justify="start"
              flex
            >
              {headline && (
                <Heading level={primary ? '2' : '3'} margin="none">
                  {headline}
                </Heading>
              )}
              {body && <Paragraph>{body}</Paragraph>}
              {buttonUrl && buttonText && (
                <GatsbyButton
                  Link={Link}
                  href={buttonUrl}
                  label={buttonText}
                  minWidth="200px"
                  primary={primary}
                />
              )}
            </Box>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
