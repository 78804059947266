import React from 'react';
import { Button } from 'grommet';
import { isExternal, forceSlashes } from '../../utils';
import FormModalContext from '../FormModal/FormModalContext';

export default function({ Link, href, ...rest }) {
  if (!href) return null;

  if (isExternal(href))
    return <Button as="a" href={href} target="_blank" rel="noopener noreferrer" {...rest} />;

  if (href === 'form')
    return (
      <FormModalContext.Consumer>
        {({ setShowModal }) => (
          <Button
            onClick={() => {
              if (window.dataLayer) window.dataLayer.push({ event: 'form-open' });
              setShowModal(true);
            }}
            {...rest}
          />
        )}
      </FormModalContext.Consumer>
    );

  const url = forceSlashes(href);
  return <Button as={Link || 'a'} to={!Link ? null : url} href={!Link ? url : null} {...rest} />;
}
