import React from 'react';
import styled from 'styled-components';
import { Box, Heading, Text, Image } from 'grommet';
import { formatPrice } from '../../utils';

const Container = styled(Box).attrs(({ isSmall, left, center, right }) => {
  let margin = '0';

  if (!isSmall && (left || right)) {
    margin = {
      vertical: 'medium',
    };
  }

  return {
    background: 'white',
    round: { size: '5px' },
    pad: center
      ? { vertical: 'large', horizontal: 'large' }
      : { vertical: 'large', horizontal: 'small' },
    margin,
    basis: 'full',
    align: 'center',
    border: { color: 'dark-1' },
    justify: 'center',
  };
})`
  ${({ isSmall, left, center, right }) => {
    if (!isSmall) {
      return `
      box-shadow: -10px 0 10px 0 rgba(0, 0, 0, 0.05), 10px 0 10px 0 rgba(0, 0, 0, 0.05);
      z-index: ${center ? 2 : 1};
      border-top-right-radius: ${left ? '0' : '5px'};
      border-right-width: ${left ? '0' : '1px'};
      border-bottom-right-radius: ${left ? '0' : '5px'};
      border-top-left-radius: ${right ? '0' : '5px'};
      border-left-width: ${right ? '0' : '1px'};
      border-bottom-left-radius: ${right ? '0' : '5px'};
      `;
    }

    return '';
  }}
`;

const Description = styled(Text).attrs({
  size: '14px',
})`
  font-style: italic;
`;

const PriceContainer = styled(Box).attrs({
  align: 'center',
  pad: { top: 'large' },
})``;

const PriceMetaText = styled(Text)``;

const PriceText = styled(Text).attrs({
  size: '80px',
  color: 'accent-2',
})`
  font-weight: bold;
`;

export default function({
  customer,
  description,
  starting,
  price,
  frequency,
  image,
  isSmall,
  left,
  center,
  right,
}) {
  return (
    <Container isSmall={isSmall} left={left} center={center} right={right}>
      <Image src={image} />
      <Heading level={3} margin={{ bottom: '0' }} textAlign="center">
        {customer}
      </Heading>
      <Description>{description}&nbsp;</Description>
      <PriceContainer>
        <PriceMetaText>{starting ? 'Starting at' : ''}&nbsp;</PriceMetaText>
        <PriceText>{formatPrice(price)}</PriceText>
        <PriceMetaText>{frequency}</PriceMetaText>
      </PriceContainer>
    </Container>
  );
}
