import React from 'react';
import styled from 'styled-components';
import { queryString } from '../../utils';

const youTubeIdRegex = /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})/i;

const options = {
  modestbranding: 1,
  playsinline: 1,
  rel: 0,
  showinfo: 0,
};

const YouTubeEmbedContainer = styled.div`
  box-shadow: 20px 20px 20px 0 rgba(0, 0, 0, 0.15);
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
`;

const YouTubeEmbedIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export default function({ url, title = '' }) {
  const match = youTubeIdRegex.exec(url);

  if (!match) return null;

  const id = match[1];
  const qs = queryString(options);
  const src = `https://www.youtube-nocookie.com/embed/${id}?${qs}`;

  return (
    id && (
      <YouTubeEmbedContainer>
        <YouTubeEmbedIframe
          src={src}
          title={title}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          frameBorder="0"
        />
      </YouTubeEmbedContainer>
    )
  );
}
