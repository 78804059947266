import React from 'react';
import { Box, Heading, ThemeContext } from 'grommet';
import GatsbyButton from '../GatsbyButton';

const theme = {
  global: { breakpoints: { small: { edgeSize: { medium: '24px' } } } },
  heading: { level: { '3': { medium: { maxWidth: '100%' } } } },
};

export default function({ Link, background, headline, buttonText, buttonUrl }) {
  return (
    <ThemeContext.Extend value={theme}>
      <Box background={background} pad={{ horizontal: 'xlarge' }}>
        <Box
          align="center"
          border="top"
          direction="row-responsive"
          gap="medium"
          pad={{ vertical: 'medium' }}
          justify="center"
        >
          {headline && <Heading level="3">{headline}</Heading>}
          {buttonUrl && buttonText && (
            <GatsbyButton
              Link={Link}
              href={buttonUrl}
              label={buttonText}
              minWidth="200px"
              primary
            />
          )}
        </Box>
      </Box>
    </ThemeContext.Extend>
  );
}
