import React from 'react';
import styled from 'styled-components';
import { Box, Heading, Image, ResponsiveContext } from 'grommet';

const StyledImage = styled(Image)`
  max-width: 315px;
  max-height: 150px;
  width: 100%;
`;

export default function({ background, headline, images }) {
  if (!images) return null;

  return (
    <Box background={background} pad="xlarge">
      <Box justify="center">
        {headline && (
          <Heading
            margin={{ top: 'none', bottom: 'medium' }}
            level="2"
            alignSelf="center"
            textAlign="center"
          >
            {headline}
          </Heading>
        )}
        <ResponsiveContext.Consumer>
          {(size) => (
            <Box alignContent="center" align="center" direction="row" justify="around" wrap>
              {images &&
                images.map((src) => (
                  <Box basis={size === 'small' ? '1/2' : null}>
                    <StyledImage alignSelf="center" src={src} fit="contain" />
                  </Box>
                ))}
            </Box>
          )}
        </ResponsiveContext.Consumer>
      </Box>
    </Box>
  );
}
