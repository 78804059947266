import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Box, Form, Heading, Layer, ResponsiveContext, ThemeContext } from 'grommet';
import { Close } from 'grommet-icons';
import FormModalContext from './FormModalContext';
import FormModalFields from './FormModalFields';
import FormModalSuccess from './FormModalSuccess';
import submitForm from './submitForm';

const CloseButton = styled(Button)`
  position: absolute;
  right: 0;
  top: 0;
`;

const FormContainer = styled(Box)`
  position: relative;
  -webkit-overflow-scrolling: touch;
`;

export default function({
  borderColor,
  buttonText,
  fields,
  headline,
  hiddenFields,
  mailSendEndpoint,
  submitType,
  success,
}) {
  const [submitted, setSubmitted] = useState(false);

  return (
    <FormModalContext.Consumer>
      {({ showModal, setShowModal }) =>
        showModal && (
          <ThemeContext.Extend value={{ layer: { background: 'rgba(0, 0, 0, 0.5)', zIndex: 20 } }}>
            <ResponsiveContext.Consumer>
              {(size) => (
                <Layer
                  margin={size === 'small' ? 'none' : 'large'}
                  responsive={false}
                  onEsc={() => {
                    setShowModal(false);
                    setSubmitted(false);
                  }}
                  onClickOutside={() => {
                    setShowModal(false);
                    setSubmitted(false);
                  }}
                >
                  <FormContainer
                    background="white"
                    border={{ color: borderColor || 'brand', size: 'large' }}
                    width="large"
                    overflow={{ vertical: 'auto', horizontal: 'hidden' }}
                  >
                    {submitted ? (
                      <FormModalSuccess setSubmitted={setSubmitted} {...success} />
                    ) : (
                      <Box pad="large" flex={false}>
                        {headline && (
                          <Heading level="2" margin={{ top: 'none' }} textAlign="center">
                            {headline}
                          </Heading>
                        )}
                        <Form
                          noValidate
                          onSubmit={(e) => {
                            if (window.dataLayer) window.dataLayer.push({ event: 'form-submit' });
                            submitForm({
                              data: e.value,
                              hiddenFields,
                              headline,
                              mailSendEndpoint,
                              submitType,
                            });
                            setSubmitted(true);
                          }}
                        >
                          <FormModalFields fields={fields} />
                          <Box align="center" justify="center" margin={{ top: 'large' }}>
                            <Button
                              type="submit"
                              label={buttonText || 'Submit'}
                              minWidth="200px"
                              primary
                            />
                          </Box>
                        </Form>
                      </Box>
                    )}
                  </FormContainer>

                  <CloseButton
                    color="dark-1"
                    style={{ padding: size === 'small' ? '12px' : '24px' }}
                    icon={<Close />}
                    onClick={() => {
                      setShowModal(false);
                      setSubmitted(false);
                    }}
                    plain
                  />
                  <iframe title="hidden-form" name="hidden-form" style={{ display: 'none' }} />
                </Layer>
              )}
            </ResponsiveContext.Consumer>
          </ThemeContext.Extend>
        )
      }
    </FormModalContext.Consumer>
  );
}
