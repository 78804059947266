import React from 'react';
import { Box, ResponsiveContext } from 'grommet';
import styled from 'styled-components';
import HighlightCard from './HighlightCard';
import SectionHeader from '../SectionHeader';

const HighlightContainer = styled(Box)`
  position: relative;
`;

export default function({ Link, cards, header }) {
  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const small = size === 'small';
        const hero = size === 'hero';

        return (
          <>
            {header && <SectionHeader {...header} />}
            <HighlightContainer
              gap="xlarge"
              pad={{
                horizontal: small || hero ? 'none' : 'xlarge',
                top: header ? 'none' : 'xlarge',
                bottom: 'xlarge',
              }}
            >
              {cards &&
                cards.map((card, index) => (
                  <HighlightCard Link={Link} reverse={(index + 1) % 2 !== 0} {...card} />
                ))}
            </HighlightContainer>
          </>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
