import React from 'react';
import { Box, Heading, Text, ResponsiveContext, ThemeContext } from 'grommet';
import Img from 'gatsby-image';
import styled from 'styled-components';
import GatsbyButton from '../GatsbyButton';

const HeroOuterContainer = styled(Box)`
  display: block;
  position: relative;
`;

const HeroContainer = styled(Box)`
  min-height: 50vh;
  position: relative;
`;

const BgImg = styled(Img)`
  ${({ small }) => (small ? 'max-height: 50vh;' : 'position: absolute !important; z-index: 0;')}
  top: 0;
  width: ${({ small }) => (small ? '200%' : '100%')};
  bottom: 0;
  left: ${({ small }) => (small ? '-100%' : '0')};
`;

export default function({
  Link,
  background,
  headline,
  body,
  image,
  buttonText,
  buttonUrl,
  buttonHeadline,
}) {
  return (
    <ThemeContext.Extend value={{ global: { breakpoints: { small: { value: 1024 } } } }}>
      <ResponsiveContext.Consumer>
        {(size) => {
          const small = ['small', 'hero'].includes(size);

          return (
            <HeroOuterContainer background={background} pad={small ? null : { bottom: '72px' }}>
              <HeroContainer align="start" background="white" direction="row-responsive">
                {BgImg && (
                  <BgImg
                    small={small}
                    objectFit="cover"
                    objectPosition="center right"
                    imgStyle={{
                      right: 0,
                      left: 'auto',
                      objectFit: 'cover',
                      objectPosition: 'center right',
                      width: small ? '50%' : '100%',
                    }}
                    fluid={image.localFile.childImageSharp.fluid}
                  />
                )}
                <Box
                  align="start"
                  alignSelf="stretch"
                  basis="1/2"
                  justify="between"
                  pad={{ top: small ? 'large' : 'xlarge', horizontal: small ? 'large' : 'xlarge' }}
                  style={{ position: 'relative' }}
                >
                  <Box>
                    {headline && (
                      <Heading level="1" margin="none">
                        {headline}
                      </Heading>
                    )}
                    {body && <Text as="p">{body}</Text>}
                  </Box>
                  <Box
                    background="accent-1"
                    pad="large"
                    width={small ? '100%' : 'medium'}
                    margin={{ bottom: small ? 'large' : '-72px' }}
                  >
                    {buttonHeadline && (
                      <Heading level="4" margin={{ top: 'none' }}>
                        {buttonHeadline}
                      </Heading>
                    )}
                    {buttonUrl && buttonText && (
                      <GatsbyButton
                        Link={Link}
                        color="dark-1"
                        href={buttonUrl}
                        label={buttonText}
                        primary
                      />
                    )}
                  </Box>
                </Box>
              </HeroContainer>
            </HeroOuterContainer>
          );
        }}
      </ResponsiveContext.Consumer>
    </ThemeContext.Extend>
  );
}
