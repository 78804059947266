import React from 'react';
import { Box, Button } from 'grommet';
import HeaderNavAnchor from './HeaderNavAnchor';
import GatsbyButton from '../GatsbyButton';

export default function({ Link, loginLink, menuLinks, mobile, primaryLink, ...rest }) {
  return (
    <>
      <Box
        as="nav"
        alignSelf="stretch"
        direction={mobile ? 'column' : 'row'}
        gap={mobile ? null : 'large'}
        pad={mobile ? 'large' : null}
        {...rest}
      >
        {menuLinks &&
          menuLinks.map(({ name, url }, index) => {
            return (
              name &&
              url && (
                <HeaderNavAnchor
                  Link={Link}
                  activeClassName="active"
                  href={url}
                  last={index >= menuLinks.length - 1}
                  mobile={mobile}
                  size={mobile ? 'large' : 'medium'}
                  partiallyActive
                >
                  {name}
                </HeaderNavAnchor>
              )
            );
          })}
      </Box>
      <Box
        direction={mobile ? 'column' : 'row'}
        gap="medium"
        pad={mobile ? { horizontal: 'large' } : { vertical: 'small' }}
      >
        {loginLink && loginLink.name && loginLink.url && (
          <Button
            color="accent-1"
            label={loginLink.name}
            href={loginLink.url}
            rel="noopener noreferrer"
            target="_blank"
          />
        )}
        {primaryLink && primaryLink.name && primaryLink.url && (
          <GatsbyButton
            Link={Link}
            color="accent-1"
            label={primaryLink.name}
            href={primaryLink.url}
            primary
          />
        )}
      </Box>
    </>
  );
}
