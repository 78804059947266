import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Box, Button, Drop, Image, ResponsiveContext } from 'grommet';
import { Close, Menu } from 'grommet-icons';
import GatsbyAnchor from '../GatsbyAnchor';
import HeaderNav from './HeaderNav';

const HeaderContainer = styled.header`
  position: sticky;
  top: 0;
  z-index: 1;
`;

const HeaderDrop = styled(Drop)`
  height: 100%;
  max-height: 100% !important;
`;

export default function({
  Link,
  background,
  loginLink,
  logoUrl,
  menuLinks,
  mobileBackground,
  primaryLink,
  ...rest
}) {
  const headerRef = useRef(null);
  const [show, setShow] = useState(false);

  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const mobile = ['small', 'hero'].includes(size);

        return (
          <HeaderContainer id="site-header">
            <Box
              align="center"
              background={background}
              direction="row"
              justify="between"
              pad={{ horizontal: 'medium' }}
              ref={headerRef}
              border={
                !mobile
                  ? null
                  : {
                      color: 'light-6',
                      side: 'bottom',
                    }
              }
              {...rest}
            >
              {logoUrl && (
                <GatsbyAnchor Link={Link} href="/" onClick={() => setShow(false)}>
                  <Box pad={{ vertical: 'small' }}>
                    <Image src={logoUrl} />
                  </Box>
                </GatsbyAnchor>
              )}
              {mobile ? (
                <>
                  <Button icon={show ? <Close /> : <Menu />} plain onClick={() => setShow(!show)} />
                  {show && (
                    <HeaderDrop
                      align={{ top: 'bottom', right: 'right' }}
                      onClickOutside={() => setShow(false)}
                      onClick={() => setShow(false)}
                      onEsc={() => setShow(false)}
                      target={headerRef.current}
                      background={mobileBackground || background}
                      plain
                    >
                      <HeaderNav
                        Link={Link}
                        loginLink={loginLink}
                        menuLinks={menuLinks}
                        primaryLink={primaryLink}
                        mobile
                      />
                    </HeaderDrop>
                  )}
                </>
              ) : (
                <HeaderNav
                  Link={Link}
                  loginLink={loginLink}
                  menuLinks={menuLinks}
                  primaryLink={primaryLink}
                  alignContent="end"
                />
              )}
            </Box>
          </HeaderContainer>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
