import React from 'react';
import styled from 'styled-components';
import { Box, Heading, Text, ResponsiveContext } from 'grommet';
import { formatPrice } from '../../utils';

const Container = styled(Box).attrs({
  background: 'white',
  round: { size: '10px' },

  basis: '1/2',
  align: 'center',
})`
  box-shadow: ${({ first, theme }) =>
    first
      ? `10px 10px 0 ${theme.global.colors['accent-3']}`
      : `10px 10px 0 ${theme.global.colors.brand}`};
  margin-right: 10px;
`;

const CustomerHeading = styled(Heading).attrs({
  level: 4,
  color: ({ first }) => (first ? 'accent-3' : 'accent-2'),
  size: '16px',
  textAlign: 'center',
})`
  text-transform: uppercase;
  margin-bottom: 0;
`;

const PriceContainer = styled(Box).attrs({
  align: 'center',
  margin: { bottom: 'large' },
})``;

const PriceMetaText = styled(Text).attrs({
  textAlign: 'center',
})`
  font-style: italic;
`;

const PriceText = styled(Text).attrs({
  size: '80px',
})`
  font-weight: bold;
`;

const Features = styled.ul`
  list-style: none;
  padding: 0;
`;

const Feature = styled.li`
  margin-bottom: 28px;
  position: relative;
  padding-left: 40px;

  svg {
    position: absolute;
    left: 0px;
    top: 2px;
  }
`;

const DisclaimerText = styled(Text).attrs({
  size: '14px',
})`
  line-height: 21px;
`;

export default function({
  customer,
  description,
  starting,
  price,
  frequency,
  features,
  disclaimer,
  first,
}) {
  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const small = size === 'small';
        return (
          <Container
            first={first}
            pad={
              small
                ? { vertical: 'medium', horizontal: 'large' }
                : { vertical: 'large', horizontal: 'xlarge' }
            }
          >
            <CustomerHeading first={first}>{customer}</CustomerHeading>
            {description && (
              <Heading level={3} margin={{ top: '10px' }} textAlign="center">
                {description}
              </Heading>
            )}
            <PriceContainer>
              <PriceMetaText>{starting ? 'Starting at' : ''}&nbsp;</PriceMetaText>
              {price && <PriceText>{formatPrice(price)}</PriceText>}
              {frequency && <PriceMetaText>{frequency}</PriceMetaText>}
            </PriceContainer>
            <Features>
              {features.map((feature) => (
                <Feature>
                  <svg width="26" height="20">
                    <path
                      fill="currentColor"
                      d="M 22.58,-0 L 9.23,13.22 3.42,7.46 0,10.85 9.23,20 26,3.39 22.58,-0 Z M 22.58,-0"
                    />
                  </svg>
                  {feature}
                </Feature>
              ))}
            </Features>
            {disclaimer && <DisclaimerText>{disclaimer}</DisclaimerText>}
          </Container>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
