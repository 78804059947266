import React from 'react';
import { Box, Heading } from 'grommet';
import ThreeUpCard from './ThreeUpCard';

export default function({ background, headline, cards }) {
  return (
    <Box background={background} pad="large">
      <Box pad="medium" justify="center">
        {headline && (
          <Heading margin={{ bottom: 'xlarge' }} level="2" alignSelf="center" textAlign="center">
            {headline}
          </Heading>
        )}
        <Box justify="between" direction="row-responsive" gap="large">
          {cards && cards.map((card) => <ThreeUpCard basis={`1/${cards.length}`} {...card} />)}
        </Box>
      </Box>
    </Box>
  );
}
