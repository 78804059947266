function submitEloqua({ data, hiddenFields }) {
  const { elqFormName, elqFormUrl, elqSiteID } = hiddenFields;
  const fields = Object.assign(data, { elqFormName, elqSiteID });

  const form = document.createElement('form');
  form.setAttribute('method', 'post');
  form.setAttribute('action', elqFormUrl);
  form.setAttribute('id', elqFormName);
  form.setAttribute('name', elqFormName);
  form.setAttribute('target', 'hidden-form');

  Object.keys(fields).forEach((key) => {
    const field = document.createElement('input');
    field.setAttribute('type', 'hidden');
    field.setAttribute('name', key);
    field.setAttribute('value', fields[key]);
    form.appendChild(field);
  });

  document.body.appendChild(form);

  form.submit();

  document.body.removeChild(form);

  return { success: true };
}

async function submitEmail({ data, headline, mailSendEndpoint }) {
  if (!data || !mailSendEndpoint) throw new Error('missing params');

  try {
    const res = await fetch(mailSendEndpoint, {
      method: 'post',
      body: JSON.stringify({
        subject: headline,
        form: data,
      }),
    });

    return res.json();
  } catch (error) {
    return { error };
  }
}

const submit = {
  eloqua: submitEloqua,
  email: submitEmail,
};

export default function submitForm({ submitType, ...rest }) {
  return submit[submitType](rest);
}
