import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import {
  Footer,
  FormModal,
  FormModalContext,
  Header,
  Layout,
  SEO,
  insightsofficerTheme,
  linkResolver,
  useShowModal,
} from '@pwc-new-ventures/ui';

const postPrefix = 'resources';

//const { GATSBY_MAIL_SEND_ENDPOINT } = process.env;
const GATSBY_MAIL_SEND_ENDPOINT  = 'https://pwc-api.netlify.com/.netlify/functions/send';

export default function({ children, description, title, image }) {
  const [showModal, setShowModal] = useShowModal();

  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
        }
      }
      contentfulFooter {
        ctaText
        ctaLink {
          slug
          internal {
            type
          }
        }
        column1Header
        column2Header
        column3Header
        column3Links {
          ...FooterLink
        }
        pwcLink
        pwcLogo {
          ...ImageSharpOriginal
        }
        socialMediaLinks {
          icon
          name
          url
        }
      }
      contentfulMainMenu {
        ctaLink {
          slug
          internal {
            type
          }
        }
        ctaText
        loginLinkText
        loginLinkUrl
        mainLogo {
          ...ImageSharpOriginal
        }
      }
      contentfulForm(slug: { eq: "form" }) {
        elqFormName
        elqFormUrl
        elqSiteID
        headline
        formFields {
          fieldLabel
          fieldName
          fieldType
          fullWidth
          hiddenValue
          selectOptions
          required
        }
        successBlurb {
          successBlurb
        }
        submitText
        submitType
        successCloseText
        successHeadline
      }
    }
  `);

  const { contentfulFooter, contentfulForm, contentfulMainMenu } = data;

  const menuLinks = [
    {
      name: 'Details',
      url: '/details/',
    },
    {
      name: 'Resources',
      url: '/resources/',
    },
    {
      name: 'Pricing',
      url: '/details/#pricing',
    },
  ];

  const header = {
    logoUrl: contentfulMainMenu.mainLogo.localFile.publicURL,
    loginLink: {
      name: contentfulMainMenu.loginLinkText,
      url: contentfulMainMenu.loginLinkUrl,
    },
    primaryLink: {
      name: contentfulMainMenu.ctaText,
      url:
        contentfulMainMenu.ctaLink &&
        linkResolver({
          type: contentfulMainMenu.ctaLink.internal.type,
          slug: contentfulMainMenu.ctaLink.slug,
          postPrefix,
        }),
    },
    menuLinks,
  };

  const footer = {
    logoUrl: contentfulFooter.pwcLogo.localFile.publicURL,
    column1Header: contentfulFooter.column1Header,
    column2Header: contentfulFooter.column2Header,
    column3Header: contentfulFooter.column3Header,
    column3Links: contentfulFooter.column3Links.map((link) => ({
      name: link.menuName,
      url: linkResolver({
        type: link.internal.type,
        slug: link.redirectUrl || link.slug,
        postPrefix,
      }),
    })),
    pwcLink: contentfulFooter.pwcLink,
    socialLinks: contentfulFooter.socialMediaLinks.map(({ icon, name, url }) => ({
      icon,
      name,
      url,
    })),
    primaryLink: {
      name: contentfulFooter.ctaText,
      url:
        contentfulFooter.ctaLink &&
        linkResolver({
          type: contentfulFooter.ctaLink.internal.type,
          slug: contentfulFooter.ctaLink.slug,
          postPrefix,
        }),
    },
    menuLinks,
  };

  const form = {
    mailSendEndpoint: GATSBY_MAIL_SEND_ENDPOINT,
    headline: contentfulForm.headline,
    buttonText: contentfulForm.submitText,
    fields: contentfulForm.formFields,
    submitType: contentfulForm.submitType,
    hiddenFields: {
      elqFormName: contentfulForm.elqFormName,
      elqFormUrl: contentfulForm.elqFormUrl,
      elqSiteID: contentfulForm.elqSiteID,
    },
    success: {
      headline: contentfulForm.successHeadline,
      body: contentfulForm.successBlurb.successBlurb,
      buttonText: contentfulForm.successCloseText,
    },
    borderColor: 'light-4',
  };

  return (
    <Layout theme={insightsofficerTheme}>
      <SEO data={data} description={description} title={title} image={image} />
      <FormModalContext.Provider value={{ showModal, setShowModal }}>
        <Header Link={Link} background="white" mobileBackground="dark-1" {...header} />
        <main>{children}</main>
        <Footer Link={Link} {...footer} />
        <FormModal {...form} />
      </FormModalContext.Provider>
    </Layout>
  );
}
