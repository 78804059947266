import React from 'react';
import styled from 'styled-components';
import { Box, Heading, ResponsiveContext } from 'grommet';
import GatsbyButton from '../GatsbyButton';

const Container = styled(Box)`
  position: sticky;
  top: ${({ theme, siteHeaderHeight }) =>
    `${siteHeaderHeight + parseInt(theme.global.edgeSize.xlarge, 10)}px`};
  max-width: ${({ isLarge }) => (isLarge ? '280px' : 'auto')};
  margin-top: ${({ isLarge, theme }) =>
    isLarge ? `${-1.5 * parseInt(theme.global.edgeSize.xlarge, 10)}px` : 0};
`;

export default class StoryStickyCTA extends React.Component {
  siteHeaderHeight = 0;

  componentDidMount() {
    const siteHeaderElement = document.getElementById('site-header');

    if (siteHeaderElement) {
      this.siteHeaderHeight = document.getElementById('site-header').offsetHeight;
    }
  }

  render() {
    const { Link, headline, ctaText, ctaLink, background, buttonColor } = this.props;

    return (
      <ResponsiveContext.Consumer>
        {(size) => {
          return (
            size !== 'small' &&
            size !== 'hero' && (
              <Container
                isLarge={size === 'medium' || size === 'large'}
                pad={{ vertical: 'medium', horizontal: 'medium' }}
                background={background}
                siteHeaderHeight={this.siteHeaderHeight}
              >
                <Heading level={4} margin={{ top: '0' }}>
                  {headline}
                </Heading>
                <GatsbyButton
                  Link={Link}
                  href={ctaLink}
                  label={ctaText}
                  primary
                  color={buttonColor}
                />
              </Container>
            )
          );
        }}
      </ResponsiveContext.Consumer>
    );
  }
}
