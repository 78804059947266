import React from 'react';
import { Anchor } from 'grommet';
import scrollToElement from 'scroll-to-element';
import { isAnchor, isExternal, forceSlashes } from '../../utils';
import FormModalContext from '../FormModal/FormModalContext';

export default function({ Link, href, ...rest }) {
  if (!href) return null;

  if (isExternal(href))
    return <Anchor as="a" href={href} target="_blank" rel="noopener noreferrer" {...rest} />;

  if (isAnchor(href))
    return (
      <Anchor
        as={Link || 'a'}
        to={href}
        onClick={() => {
          const hash = href.slice(href.lastIndexOf('#'));
          scrollToElement(hash, { duration: 600 });
        }}
        {...rest}
      />
    );

  if (href === 'form')
    return (
      <FormModalContext.Consumer>
        {({ setShowModal }) => <Anchor onClick={() => setShowModal(true)} {...rest} />}
      </FormModalContext.Consumer>
    );

  const url = forceSlashes(href);
  return <Anchor as={Link || 'a'} to={!Link ? null : url} href={!Link ? url : null} {...rest} />;
}
