import React from 'react';
import HeroMain from '../components/HeroMain';
import ThreeUp from '../components/ThreeUp';
import CtaSection from '../components/CtaSection';
import HighlightList from '../components/HighlightList';
import LogoGridCustomer from '../components/LogoGridCustomer';
import StoryList from '../components/StoryList';

export default function({ doc, Link }) {
  if (!doc) return null;

  const { hero, highlights, threeUp, logos, stories, cta } = doc;

  return (
    <>
      <HeroMain Link={Link} {...hero} />
      <ThreeUp {...threeUp} />
      <HighlightList Link={Link} {...highlights} />
      <LogoGridCustomer {...logos} />
      <StoryList Link={Link} {...stories} />
      <CtaSection Link={Link} {...cta} />
    </>
  );
}
