import React from 'react';
import { Box, Heading, Image, Paragraph } from 'grommet';

export default function({ body, image, headline, ...rest }) {
  return (
    <Box align="center" alignContent="center" {...rest}>
      <Box height="64px" width="64px">
        {image && <Image src={image} fit="contain" />}
      </Box>
      {headline && (
        <Heading margin={{ bottom: 'none', top: 'large' }} level="3" textAlign="center">
          {headline}
        </Heading>
      )}
      {body && (
        <Paragraph margin={{ top: 'none' }} textAlign="center">
          {body}
        </Paragraph>
      )}
    </Box>
  );
}
