import React from 'react';
import Helmet from 'react-helmet';

export default function({ data, title, image, description = '', lang = 'en' }) {
  const titleTemplate = `%s : ${data.site.siteMetadata.title}`;

  return (
    <Helmet htmlAttributes={{ lang }} title={title} titleTemplate={titleTemplate}>
      {description && <meta name="description" content={description} />}
      {image && <meta name="image" content={image} />}
      <meta property="og:type" content="website" />
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {image && <meta property="og:image" content={image} />}
      <meta name="twitter:card" content="summary" />
      {title && <meta name="twitter:title" content={title} />}
      {description && <meta name="twitter:description" content={description} />}
      {image && <meta name="twitter:image" content={image} />}
    </Helmet>
  );
}
