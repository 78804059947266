import React from 'react';
import { Box, FormField, MaskedInput, Select, ThemeContext } from 'grommet';

const emailValidation = /\b[\w.-]+@[\w.-]+\.\w{2,4}\b/i;
const phoneValidation = /^\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}/i;
const phoneMask = [
  { fixed: '(' },
  {
    length: 3,
    regexp: /^[0-9]{1,3}$/,
    placeholder: 'xxx',
  },
  { fixed: ')' },
  { fixed: ' ' },
  {
    length: 3,
    regexp: /^[0-9]{1,3}$/,
    placeholder: 'xxx',
  },
  { fixed: '-' },
  {
    length: 4,
    regexp: /^[0-9]{1,4}$/,
    placeholder: 'xxxx',
  },
];

function FormFieldContainer({ fullWidth, children }) {
  return (
    <Box basis={fullWidth ? 'full' : '1/2'} pad={{ horizontal: 'small' }}>
      {children}
    </Box>
  );
}

export default function({ fields }) {
  return (
    <Box direction="row-responsive" wrap>
      {fields &&
        fields.map(
          ({
            fieldLabel,
            fieldName,
            fieldType,
            fullWidth,
            hiddenValue,
            selectOptions,
            required,
          }) => {
            switch (fieldType) {
              case 'email':
                return (
                  <FormFieldContainer fullWidth={fullWidth}>
                    <FormField
                      type="email"
                      label={fieldLabel}
                      name={fieldName}
                      required={required}
                      validate={{ regexp: emailValidation }}
                    />
                  </FormFieldContainer>
                );
              case 'phone':
                return (
                  <FormFieldContainer fullWidth={fullWidth}>
                    <FormField
                      component={MaskedInput}
                      label={fieldLabel}
                      mask={phoneMask}
                      name={fieldName}
                      required={required}
                      validate={{ regexp: phoneValidation }}
                    />
                  </FormFieldContainer>
                );
              case 'select':
                return (
                  <FormFieldContainer fullWidth={fullWidth}>
                    <ThemeContext.Extend
                      value={{
                        global: {
                          active: { color: 'dark-1' },
                          drop: {
                            background: 'light-7',
                            border: 'border',
                            extend: () => `border: 1px solid #212121;`,
                          },
                          hover: {
                            color: {
                              dark: 'dark-1',
                              light: 'dark-1',
                            },
                          },
                        },
                      }}
                    >
                      <FormField
                        label={fieldLabel}
                        component={Select}
                        name={fieldName}
                        options={selectOptions}
                        required={required}
                      />
                    </ThemeContext.Extend>
                  </FormFieldContainer>
                );
              case 'hidden':
                return (
                  <FormField
                    style={{ display: 'none' }}
                    name={fieldName}
                    value={hiddenValue}
                    hidden
                  />
                );
              default:
                return (
                  <FormFieldContainer fullWidth={fullWidth}>
                    <FormField label={fieldLabel} name={fieldName} required={required} />
                  </FormFieldContainer>
                );
            }
          },
        )}
    </Box>
  );
}
