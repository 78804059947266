import React from 'react';
import { Box, Heading } from 'grommet';
import GatsbyButton from '../GatsbyButton';

export default function({ Link, background, headline, buttonText, buttonUrl }) {
  return (
    <Box background={background} pad={{ horizontal: 'xlarge' }} align="center">
      {headline && (
        <Heading level="3" textAlign="center">
          {headline}
        </Heading>
      )}
      {buttonUrl && buttonText && (
        <GatsbyButton
          Link={Link}
          href={buttonUrl}
          label={buttonText}
          color="accent-1"
          minWidth="200px"
          primary
        />
      )}
    </Box>
  );
}
