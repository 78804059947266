import React from 'react';
import { Anchor, Box, Button, Heading, Image, Paragraph, ResponsiveContext } from 'grommet';
import { Facebook, Instagram, Linkedin, Twitter, Youtube } from 'grommet-icons';
import GatsbyAnchor from '../GatsbyAnchor';
import GatsbyButton from '../GatsbyButton';

const Icons = {
  facebook: Facebook,
  instagram: Instagram,
  linkedin: Linkedin,
  twitter: Twitter,
  youtube: Youtube,
};

export default function({
  Link,
  logoUrl,
  column1Header,
  column2Header,
  column3Header,
  column3Links,
  pwcLink,
  socialLinks,
  primaryLink,
  menuLinks,
}) {
  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const mobile = size === 'small';
        const hero = size === 'hero';
        const align = mobile || hero ? 'center' : 'start';
        const pad = {
          vertical: mobile || hero ? 'medium' : 'none',
        };

        return (
          <Box background="dark-2" pad={{ horizontal: 'large', top: 'xlarge', bottom: 'large' }}>
            <Box direction="row" align="start" justify="between" wrap>
              <Box basis={mobile || hero ? 'full' : '40%'} align={align} justify="start" pad={pad}>
                {logoUrl && (
                  <GatsbyAnchor Link={Link} href="/">
                    <Box pad={{ vertical: 'small' }}>
                      <Image src={logoUrl} />
                    </Box>
                  </GatsbyAnchor>
                )}
                {pwcLink && (
                  <Anchor href={pwcLink} size="small" color="white">
                    {pwcLink}
                  </Anchor>
                )}
              </Box>
              <Box basis={mobile ? 'full' : '20%'} align={align} justify="start" pad={pad}>
                {column1Header && (
                  <Heading level="4" margin={{ top: 'none' }} color="brand" size="small" uppercase>
                    {column1Header}
                  </Heading>
                )}
                {primaryLink && (
                  <GatsbyButton
                    Link={Link}
                    color="brand"
                    fill={mobile}
                    href={primaryLink.url}
                    label={primaryLink.name}
                  />
                )}
                <Box direction="row" margin={{ vertical: 'medium' }} gap="medium">
                  {socialLinks &&
                    socialLinks.length &&
                    socialLinks.map(({ icon, name, url }) => {
                      const Icon = Icons[icon.toLowerCase()];

                      return (
                        Icon &&
                        url && (
                          <Button
                            a11yTitle={name}
                            href={url}
                            icon={<Icon color="white" size="32px" />}
                            target="_blank"
                            rel="noopener noreferrer"
                            plain
                          />
                        )
                      );
                    })}
                </Box>
              </Box>
              <Box basis={mobile ? '1/2' : '20%'} align={align} justify="start" pad={pad}>
                {column2Header && (
                  <Heading level="4" margin={{ top: 'none' }} color="brand" size="small" uppercase>
                    {column2Header}
                  </Heading>
                )}
                {menuLinks &&
                  menuLinks.map(({ name, url }) => {
                    return (
                      url &&
                      name && (
                        <GatsbyAnchor Link={Link} href={url} size="small" color="white">
                          {name}
                        </GatsbyAnchor>
                      )
                    );
                  })}
              </Box>

              <Box basis={mobile ? '1/2' : '20%'} align={align} justify="start" pad={pad}>
                {column3Header && (
                  <Heading level="4" margin={{ top: 'none' }} color="brand" size="small" uppercase>
                    {column3Header}
                  </Heading>
                )}
                {column3Links &&
                  column3Links.map(({ name, url }) => {
                    return (
                      url &&
                      name && (
                        <GatsbyAnchor Link={Link} href={url} size="small" color="white">
                          {name}
                        </GatsbyAnchor>
                      )
                    );
                  })}
              </Box>
            </Box>
            <Paragraph size="small" textAlign={mobile ? 'center' : 'start'}>
              <i>© {new Date().getFullYear()} PwC. All rights reserved.</i>
            </Paragraph>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
