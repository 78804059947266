import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import styled from 'styled-components';
import { Anchor, Box, Heading, ResponsiveContext, Text } from 'grommet';
import GatsbyAnchor from '../components/GatsbyAnchor';
import StoryHeader from '../components/StoryHeader';
import StoryStickyCTA from '../components/StoryStickyCTA';
import CtaBanner from '../components/CtaBanner';
import CtaBannerAlt from '../components/CtaBannerAlt';
import StoryList from '../components/StoryList';

const StyledImg = styled.img`
  max-width: 100%;
  height: auto;
`;

function options(Link) {
  return {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => <Heading level="1">{children}</Heading>,
      [BLOCKS.HEADING_2]: (node, children) => <Heading level="2">{children}</Heading>,
      [BLOCKS.HEADING_3]: (node, children) => <Heading level="3">{children}</Heading>,
      [BLOCKS.HEADING_4]: (node, children) => <Heading level="4">{children}</Heading>,
      [BLOCKS.HEADING_5]: (node, children) => <Heading level="5">{children}</Heading>,
      [BLOCKS.HEADING_6]: (node, children) => <Heading level="6">{children}</Heading>,
      [BLOCKS.PARAGRAPH]: (node, children) => <Text as="p">{children}</Text>,
      [BLOCKS.EMBEDDED_ASSET]: (node) => (
        <StyledImg
          src={node.data.target.fields.file['en-US'].url}
          alt={node.data.target.fields.title['en-US']}
          width={node.data.target.fields.file['en-US'].details.image.width}
          height={node.data.target.fields.file['en-US'].details.image.height}
        />
      ),
      [INLINES.HYPERLINK]: (node) =>
        node.data.uri.startsWith('http') || !Link ? (
          <Anchor href={node.data.uri}>{node.content[0].value}</Anchor>
        ) : (
          <GatsbyAnchor Link={Link} href={node.data.uri}>
            {node.content[0].value}
          </GatsbyAnchor>
        ),
      [INLINES.ENTRY_HYPERLINK]: (node) => (
        <GatsbyAnchor Link={Link} href={node.data.target.fields.slug['en-US']}>
          {node.content[0].value}
        </GatsbyAnchor>
      ),
      [INLINES.ASSET_HYPERLINK]: (node) => (
        <Anchor
          href={node.data.target.fields.file['en-US'].url}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          {node.content[0].value}
        </Anchor>
      ),
    },
  };
}

export default function({ doc, Link }) {
  const {
    body,
    header,
    floatingCta,
    spacerNavigator,
    closingBackground,
    relatedPosts,
    closingNavigator,
  } = doc;

  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const isSmall = size !== 'medium' && size !== 'large';

        return (
          <>
            <StoryHeader {...header} />
            <Box
              pad={isSmall ? 'large' : 'xlarge'}
              direction="row"
              wrap
              style={{ position: 'relative' }}
            >
              <Box basis={isSmall ? 'full' : '2/3'}>
                {body && documentToReactComponents(body.json, options(Link))}
              </Box>
              <Box align={isSmall ? 'stretch' : 'end'} basis={isSmall ? 'full' : '1/3'}>
                {floatingCta && <StoryStickyCTA {...floatingCta} Link={Link} />}
              </Box>
            </Box>
            {spacerNavigator && <CtaBanner Link={Link} {...spacerNavigator} />}
            <Box background={closingBackground} pad={{ bottom: 'xlarge' }}>
              {relatedPosts && (
                <StoryList
                  Link={Link}
                  headline={relatedPosts.headline}
                  background={closingBackground}
                  cards={relatedPosts.cards}
                />
              )}
              {closingNavigator && <CtaBannerAlt Link={Link} {...closingNavigator} />}
            </Box>
          </>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
