import styled from 'styled-components';
import GatsbyAnchor from '../GatsbyAnchor';

export default styled(GatsbyAnchor)`
  align-items: center;
  color: var(--dark-1);
  display: flex;
  justify-content: center;
  position: relative;
  text-decoration: none;
  transition: 0.1s ease-in-out;

  ${(props) => (props.mobile ? 'padding: 1rem 0;' : '')}
  ${(props) => (props.mobile && !props.last ? 'border-bottom: 1px solid #ffc940;' : '')}

  &:hover {
    color: var(--accent-2);
  }

  &.active {
    color: var(--accent-2);

    &:before {
      background-color: var(--accent-2);
      content: '';
      display: block;
      height: 6px;
      left: calc(50% - 20px);
      position: absolute;
      top: 0;
      width: 40px;
    }
  }
`;
