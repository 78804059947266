import React from 'react';
import { Box, Heading, Text, ResponsiveContext, ThemeContext } from 'grommet';
import Img from 'gatsby-image';
import styled from 'styled-components';
import GatsbyButton from '../GatsbyButton';

const HeroContainer = styled(Box)`
  min-height: 66.667vh;
  position: relative;
`;

const BgImg = styled(Img)`
  ${({ small }) => (small ? 'max-height: 50vh;' : 'position: absolute !important; z-index: -1;')}
  top: 0;
  width: ${({ small }) => (small ? '200%' : '100%')};
  bottom: 0;
  left: ${({ small }) => (small ? '-100%' : '0')};
`;

export default function({ Link, headline, body, image, buttonText, buttonUrl }) {
  return (
    <ThemeContext.Extend value={{ global: { breakpoints: { small: { value: 1024 } } } }}>
      <ResponsiveContext.Consumer>
        {(size) => {
          const small = ['small', 'hero'].includes(size);

          return (
            <HeroContainer align="center" direction="row-responsive">
              {BgImg && (
                <BgImg
                  small={small}
                  objectFit="cover"
                  objectPosition={small ? 'center right' : 'center center'}
                  imgStyle={{
                    right: 0,
                    left: 'auto',
                    objectFit: 'cover',
                    objectPosition: small ? 'center right' : 'center center',
                    width: small ? '50%' : '100%',
                  }}
                  fluid={image.localFile.childImageSharp.fluid}
                />
              )}
              <Box align="start" basis="1/2" pad={small ? 'large' : 'xlarge'} justify="start">
                {headline && (
                  <Heading level="1" margin="none">
                    {headline}
                  </Heading>
                )}
                {body && <Text as="p">{body}</Text>}
                {buttonUrl && buttonText && (
                  <GatsbyButton Link={Link} href={buttonUrl} label={buttonText} primary />
                )}
              </Box>
            </HeroContainer>
          );
        }}
      </ResponsiveContext.Consumer>
    </ThemeContext.Extend>
  );
}
