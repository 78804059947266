import React from 'react';
import { Box, Heading, Image, Paragraph, ResponsiveContext } from 'grommet';
import GatsbyButton from '../GatsbyButton';

export default function({
  Link,
  headline,
  body,
  buttonText,
  buttonUrl,
  image,
  imageLarge,
  reverse,
}) {
  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const direction = !reverse ? 'row' : 'row-reverse';
        const small = size === 'small';
        const imageBasis = imageLarge ? '3/4' : '1/2';
        const hide = small && !imageLarge;

        return (
          <Box direction={small ? 'column-reverse' : direction} align={small ? 'start' : 'center'}>
            {!hide && (
              <Box
                flex
                basis={small ? 'auto' : imageBasis}
                fill="horizontal"
                justify={imageLarge ? 'end' : 'center'}
                align={reverse ? 'end' : 'start'}
                alignSelf={imageLarge ? 'end' : 'center'}
                pad={
                  imageLarge
                    ? 'none'
                    : {
                        vertical: 'xlarge',
                        left: !reverse || small ? 'none' : 'xlarge',
                        right: !reverse && !small ? 'xlarge' : 'none',
                        top: 'none',
                      }
                }
              >
                {image && <Image width="100%" src={image} />}
              </Box>
            )}
            <Box
              align="start"
              basis={small ? 'auto' : '1/2'}
              justify="start"
              pad={small ? { horizontal: 'large', vertical: 'xlarge' } : 'xlarge'}
              flex
            >
              {headline && (
                <Heading level="2" margin="none">
                  {headline}
                </Heading>
              )}
              {body && <Paragraph>{body}</Paragraph>}
              {buttonUrl && buttonText && (
                <GatsbyButton
                  Link={Link}
                  href={buttonUrl}
                  label={buttonText}
                  minWidth="200px"
                  primary
                />
              )}
            </Box>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
