import React from 'react';
import { Box, Heading, Text } from 'grommet';

export default function() {
  return (
    <>
      <Box pad="medium">
        <Heading level="1">Not Found</Heading>
        <Text as="p">You just hit a route that doesn’t exist… the sadness.</Text>
      </Box>
    </>
  );
}
