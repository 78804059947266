import React from 'react';
import { Box, Heading, Paragraph, ResponsiveContext, ThemeContext } from 'grommet';

export default function({ headline, body }) {
  return (
    <ResponsiveContext.Consumer>
      {(size) => (
        <Box align="center" pad={size === 'small' ? 'large' : 'xlarge'}>
          <ThemeContext.Extend
            value={{
              heading: { level: { '2': { medium: { maxWidth: '640px' } } } },
              paragraph: { medium: { maxWidth: '800px' } },
            }}
          >
            {headline && (
              <Heading level="2" margin="none" textAlign="center">
                {headline}
              </Heading>
            )}
            {body && (
              <Paragraph margin="none" textAlign="center">
                {body}
              </Paragraph>
            )}
          </ThemeContext.Extend>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
}
